import {
  LANGUAGES_REQUESTING,
  LANGUAGES_SUCCESS,
  LANGUAGES_ERROR,
} from './constants';

const initialState = {
  requesting: false,
  successful: false,
  languages: [],
  errors: [],
};

const reducer = function languagesReducer(state = initialState, action) {
  switch (action.type) {
    case LANGUAGES_REQUESTING:
      return {
        requesting: true,
        successful: false,
        languages: [state.languages],
        errors: [],
      };

    case LANGUAGES_SUCCESS:
      return {
        errors: [],
        languages: action.response,
        requesting: false,
        successful: true,
      };

    case LANGUAGES_ERROR:
      return {
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        languages: [],
        requesting: false,
        successful: false,
      };

    default:
      return state;
  }
};

export default reducer;
