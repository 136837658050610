import moment from 'moment';
import {
  FILTER,
} from './constants';

const initialState = {
  date: moment(new Date()).format('YYYY-MM-DD'),
  locations: '',
  eventTypes: '',
  language: '',
  time: '',
};

const reducer = function signupReducer(state = initialState, action) {
  switch (action.type) {
    case FILTER:
      if (action.date == undefined) {
        if (state.date == undefined) {
          return {
            ...state,
            date: moment(new Date()).format('YYYY-MM-DD'),
          };
        }
        return {
          ...state,
        };
      }
      return {
        date: action.date,
        locations: action.locations,
        eventTypes: action.eventTypes,
        languages: action.languages,
        time: action.time,
      };

    default:
      return state;
  }
};
export default reducer;
