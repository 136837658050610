import moment from 'moment';
import {
  EVENTS_REQUESTING,
  EVENTS_SUCCESS,
  EVENTS_ERROR,
} from './constants';

const initialState = {
  requesting: false,
  successful: false,
  events: [],
  events_by_location: {},
  errors: [],
};

const reducer = function signupReducer(state = initialState, action) {
  switch (action.type) {
    case EVENTS_REQUESTING:
      return {
        requesting: true,
        successful: false,
        events: state.events,
        events_by_location: state.events_by_location,
        errors: [],
      };

    case EVENTS_SUCCESS:
      const newResp = {};
      const currentTime = new Date();

      for (const ev of action.response) {
        const eventTime = new Date(`${ev.date}T${ev.time}`);

        const diff = eventTime.valueOf() - currentTime.valueOf();
        const diffInHours = diff / 1000 / 60 / 60; // Convert milliseconds to hours

        let isNextHour = false;
        let isNextThreeHours = false;

        if (diffInHours < 1 && !ev.cancelled) {
          isNextHour = true;
        } else if (diffInHours < 3 && !ev.cancelled) {
          isNextThreeHours = true;
        }

        if (ev.location in newResp) {
          newResp[ev.location].events.push(ev);
          if (newResp.isNextThreeHours == false) {
            newResp.isNextThreeHours = isNextThreeHours;
          }
          if (newResp.isNextHour == false) {
            newResp.isNextHour = isNextThreeHours;
          }
        } else {
          newResp[ev.location] = {
            events: [ev],
            isNextThreeHours,
            isNextHour,
            inViewport: false,
          };
        }
      }
      // TODO: delete ev["location after push"
      const dict = {
        errors: [],
        events: action.response,
        events_by_location: newResp,
        requesting: false,
        successful: true,
      };
      return dict;

    case EVENTS_ERROR:
      return {
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        events: [],
        events_by_location: {},
        requesting: false,
        successful: false,
      };

    default:
      return state;
  }
};

export default reducer;
