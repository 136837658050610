import {
  LOCATION_TYPES_REQUESTING,
  LOCATION_TYPES_SUCCESS,
  LOCATION_TYPES_ERROR,
} from './constants';

const initialState = {
  requesting: false,
  successful: false,
  locationTypes: [],
  errors: [],
};

const reducer = function locationTypesReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_TYPES_REQUESTING:
      return {
        requesting: true,
        successful: false,
        locationTypes: [state.locationTypes],
        errors: [],
      };

    case LOCATION_TYPES_SUCCESS:

      return {
        errors: [],
        locationTypes: action.response,
        requesting: false,
        successful: true,
      };

    case LOCATION_TYPES_ERROR:
      return {
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        locationTypes: [],
        requesting: false,
        successful: false,
      };

    default:
      return state;
  }
};

export default reducer;
