import { call, put, takeLatest } from 'redux-saga/effects';
import { handleApiErrors } from '../../lib/api-errors';
import {
  LOCATIONS_REQUESTING,
  LOCATIONS_SUCCESS,
  LOCATIONS_ERROR,
} from '../../datasources/Locations/constants';

import {
  LOCATION_TYPES_SUCCESS,
  LOCATION_TYPES_ERROR,
} from '../../datasources/LocationTypes/constants';

import { BACKEND_BASE_URL } from '../../config/index';
import { EVENTS_SUCCESS, EVENTS_ERROR } from '../../datasources/Events/constants';
import { EVENT_TYPES_ERROR, EVENT_TYPES_SUCCESS } from '../../datasources/EventTypes/constants';
import { LANGUAGES_ERROR, LANGUAGES_SUCCESS } from '../../datasources/Languages/constants';
import { LOCATION_IMAGES_ERROR, LOCATION_IMAGES_SUCCESS } from '../../datasources/LocationImages/constants';
import { LOCATION_CHURCHCOMMUNITIES_ERROR, LOCATION_CHURCHCOMMUNITIES_SUCCESS } from '../../datasources/LocationChurchcommunities/constants';

const locationsUrl = `${BACKEND_BASE_URL}/api/v1/locations/`;
const locationTypesUrl = `${BACKEND_BASE_URL}/api/v1/locationtypes/`;
const eventsUrl = `${BACKEND_BASE_URL}/api/v1/events/`;
const eventTypesUrl = `${BACKEND_BASE_URL}/api/v1/eventtypes/`;
const languagesUrl = `${BACKEND_BASE_URL}/api/v1/languages/`;
const locationImagesUrl = `${BACKEND_BASE_URL}/api/v1/locationimages/`;
const locationChurchcommunitiesUrl = `${BACKEND_BASE_URL}/api/v1/locationchurchcommunities/`;

function callGetApi(url) {
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(handleApiErrors) // we'll make this in a second
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => { throw error; });
}

function eventsApi() {
  return callGetApi(eventsUrl);
}

function eventTypesApi() {
  return callGetApi(eventTypesUrl);
}

function languagesApi() {
  return callGetApi(languagesUrl);
}

function locationTypesApi() {
  return callGetApi(locationTypesUrl);
}

function locationsApi() {
  return callGetApi(locationsUrl);
}

function locationImagesApi() {
  return callGetApi(locationImagesUrl);
}
function locationChurchcommunitiesUrlApi() {
  return callGetApi(locationChurchcommunitiesUrl);
}

// This will be run when the LOCATIONS_REQUESTING
// Action is found by the watcher
function* locationsFlow() {
  try {
    // pulls "calls" to our signupApi with our email and password
    // from our dispatched signup action, and will PAUSE
    // here until the API async function, is complete!
    const response = yield call(locationsApi);
    // when the above api call has completed it will "put",
    // or dispatch, an action of type LOCATIONS_SUCCESS with
    // the successful response.
    yield put({ type: LOCATIONS_SUCCESS, response });

    try {
      const response = yield call(eventTypesApi);

      yield put({ type: EVENT_TYPES_SUCCESS, response });
    } catch (error) {
      // if the api call fails, it will "put" the LOCATIONS_ERROR
      // into the dispatch along with the error.
      yield put({ type: EVENT_TYPES_ERROR, error });
    }

    try {
      const response = yield call(locationTypesApi);

      yield put({ type: LOCATION_TYPES_SUCCESS, response });
    } catch (error) {
      // if the api call fails, it will "put" the LOCATIONS_ERROR
      // into the dispatch along with the error.
      yield put({ type: LOCATION_TYPES_ERROR, error });
    }

    try {
      const response = yield call(locationImagesApi);

      yield put({ type: LOCATION_IMAGES_SUCCESS, response });
    } catch (error) {
      // if the api call fails, it will "put" the LOCATIONS_ERROR
      // into the dispatch along with the error.
      yield put({ type: LOCATION_IMAGES_ERROR, error });
    }
    try {
      const response = yield call(locationChurchcommunitiesUrlApi);
      yield put({ type: LOCATION_CHURCHCOMMUNITIES_SUCCESS, response });
    } catch (error) {
      // if the api call fails, it will "put" the LOCATIONS_ERROR
      // into the dispatch along with the error.
      yield put({ type: LOCATION_CHURCHCOMMUNITIES_ERROR, error });
    }

    try {
      const response = yield call(eventsApi);
      yield put({ type: EVENTS_SUCCESS, response });
    } catch (error) {
      // if the api call fails, it will "put" the LOCATIONS_ERROR
      // into the dispatch along with the error.
      yield put({ type: EVENTS_ERROR, error });
    }

    try {
      const response = yield call(languagesApi);
      yield put({ type: LANGUAGES_SUCCESS, response });
    } catch (error) {
      // if the api call fails, it will "put" the LOCATIONS_ERROR
      // into the dispatch along with the error.
      yield put({ type: LANGUAGES_ERROR, error });
    }
  } catch (error) {
    // if the api call fails, it will "put" the LOCATIONS_ERROR
    // into the dispatch along with the error.
    yield put({ type: LOCATIONS_ERROR, error });
  }
}

// Watches for the LOCATIONS_REQUESTING action type
// When it gets it, it will call locationsFlow()
// WITH the action we dispatched
function* locationsWatcher() {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!

  yield takeLatest(LOCATIONS_REQUESTING, locationsFlow);
}

export default locationsWatcher;
