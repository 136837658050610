import {
  EVENT_TYPES_REQUESTING,
  EVENT_TYPES_SUCCESS,
  EVENT_TYPES_ERROR,
} from './constants';

const initialState = {
  requesting: false,
  successful: false,
  eventTypes: [],
  errors: [],
};

const reducer = function eventTypesReducer(state = initialState, action) {
  switch (action.type) {
    case EVENT_TYPES_REQUESTING:
      return {
        requesting: true,
        successful: false,
        eventTypes: [state.eventTypes],
        errors: [],
      };

    case EVENT_TYPES_SUCCESS:
      return {
        errors: [],
        eventTypes: action.response,
        requesting: false,
        successful: true,
      };

    case EVENT_TYPES_ERROR:
      return {
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        eventTypes: [],
        requesting: false,
        successful: false,
      };

    default:
      return state;
  }
};

export default reducer;
