import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { Card, Drawer } from '@mui/material';
import store from './reducer';
import * as serviceWorker from './serviceWorker';

const EventDisplay = React.lazy(() => import('./components/EventDisplay/index'));
const Map = React.lazy(() => import('./components/Map'));

function MyBottomNavigation() {
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
    >
      <BottomNavigationAction label="Karte" icon={<MapIcon />} component={Link} to="/" />
      <BottomNavigationAction label="Liste" icon={<ListIcon />} component={Link} to="/events" />
      <BottomNavigationAction label="Favoriten" icon={<LocationOnIcon />} />
    </BottomNavigation>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={(
              <div>
                {window.innerWidth > 1400
                  ? (
                    <Drawer
                      open
                      variant="persistent"
                      anchor="right"
                      PaperProps={{
                        sx: { width: '25%' },
                      }}
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <EventDisplay />
                      </Suspense>
                    </Drawer>
                  )
                  : <></>}
                <Map />
                {window.innerWidth < 1400
                  ? <MyBottomNavigation /> : <></>}
              </div>
          )}
          />

          <Route
            path="/events/"
            element={(
              <div>
                <Suspense fallback={<div>Loading...</div>}>
                  <EventDisplay />
                </Suspense>
                <MyBottomNavigation />
              </div>
          )}
          />
        </Routes>
      </BrowserRouter>
      ,
    </Provider>
  </LocalizationProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
