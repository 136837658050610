import {
  applyMiddleware, combineReducers, compose, createStore,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import eventsReducer from '../datasources/Events/reducers';
import eventTypesReducer from '../datasources/EventTypes/reducers';

import locationsReducer from '../datasources/Locations/reducers';
import locationTypesReducer from '../datasources/LocationTypes/reducers';
import locationImagesReducer from '../datasources/LocationImages/reducers';
import languagesReducer from '../datasources/Languages/reducers';
import filterReducer from '../datasources/Filter/reducers';
import locationChurchcommunitiesReducer from '../datasources/LocationChurchcommunities/reducers';
import rootSaga from '../sagas/index';

const sagaMiddleware = createSagaMiddleware();
const IndexReducer = combineReducers({
  locations: locationsReducer,
  locationTypes: locationTypesReducer,
  events: eventsReducer,
  eventTypes: eventTypesReducer,
  languages: languagesReducer,
  filter: filterReducer,
  locationImages: locationImagesReducer,
  locationChurchcommunities: locationChurchcommunitiesReducer,
});

const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
/* eslint-enable */

const store = createStore(
  IndexReducer,
  composeSetup(applyMiddleware(sagaMiddleware)), // allows redux devtools to watch sagas
);

// Begin our Index Saga
sagaMiddleware.run(rootSaga);

export default store;
