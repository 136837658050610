import {
  LOCATIONS_REQUESTING,
  LOCATIONS_SUCCESS,
  LOCATIONS_ERROR,
  LOCATIONS_SELECTION,
  LOCATIONS_UNSELECT,
} from './constants';

const initialState = {
  select_all: true,
  requesting: false,
  successful: false,
  locations: [],
  errors: [],
  locations_filter: [],
};

const reducer = function locationsReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATIONS_REQUESTING:
      return {
        select_all: true,
        requesting: true,
        successful: false,
        locations: state.locations,
        errors: [],
        locations_filter: [],
      };

    case LOCATIONS_SUCCESS:
      return {
        select_all: true,
        errors: [],
        locations: action.response,
        requesting: false,
        successful: true,
        locations_filter: [],
      };

    case LOCATIONS_ERROR:
      return {
        select_all: true,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        locations: [],
        requesting: false,
        successful: false,
        locations_filter: [],
      };

    case LOCATIONS_SELECTION:
      var filtered_location = [];

      state.locations.map((location) => {
        if (location.location_name == action.value[0].value) {
          filtered_location.push(location);
        }
      });
      return {
        select_all: false,
        requesting: true,
        successful: true,
        locations: state.locations,
        errors: [],
        locations_filter: filtered_location,
      };
    case LOCATIONS_UNSELECT:

      return {
        select_all: true,
        requesting: true,
        successful: true,
        locations: state.locations,
        errors: [],
        locations_filter: [],
      };
    default:
      return state;
  }
};

export default reducer;
