import {
  LOCATION_CHURCHCOMMUNITIES_REQUESTING,
  LOCATION_CHURCHCOMMUNITIES_SUCCESS,
  LOCATION_CHURCHCOMMUNITIES_ERROR,
} from './constants';

const initialState = {
  requesting: false,
  successful: false,
  locationChurchcommunities: {},
  errors: [],
};

const reducer = function locationChurchcommunitiesReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHURCHCOMMUNITIES_REQUESTING:
      return {
        requesting: true,
        successful: false,
        locationChurchcommunities: state.locationChurchcommunities,
        errors: [],
      };

    case LOCATION_CHURCHCOMMUNITIES_SUCCESS:
      const newDict = {};
      for (const i of action.response) {
        if (newDict[i.location_name] === undefined) {
          newDict[i.location_name] = [i.church_community_name];
        } else {
          newDict[i.location_name].push(i.church_community_name);
        }
      }

      return {
        errors: [],
        locationChurchcommunities: newDict,
        requesting: false,
        successful: true,
      };

    case LOCATION_CHURCHCOMMUNITIES_ERROR:
      return {
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        locationChurchcommunities: {},
        requesting: false,
        successful: false,
      };

    default:
      return state;
  }
};

export default reducer;
