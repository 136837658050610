import {
  LOCATION_IMAGES_REQUESTING,
  LOCATION_IMAGES_SUCCESS,
  LOCATION_IMAGES_ERROR,
} from './constants';

const initialState = {
  requesting: false,
  successful: false,
  location_images: {},
  errors: [],
};

const reducer = function locationImagesReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_IMAGES_REQUESTING:
      return {
        requesting: true,
        successful: false,
        location_images: state.location_images,
        errors: [],
      };

    case LOCATION_IMAGES_SUCCESS:
      const newDict = {};
      for (const i of action.response) {
        if (newDict[i.location] === undefined) {
          newDict[i.location] = [i.image];
        } else {
          newDict[i.location].push(i.image);
        }
      }

      return {
        errors: [],
        location_images: newDict,
        requesting: false,
        successful: true,
      };

    case LOCATION_IMAGES_ERROR:
      return {
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        location_images: {},
        requesting: false,
        successful: false,
      };

    default:
      return state;
  }
};

export default reducer;
